import type { BoundaryPaddingLocation } from '@aurora/shared-client/components/form/enums';
import type { FontStyleName } from '@aurora/shared-client/helpers/fonts/types';
import type { NodePageAndParams } from '@aurora/shared-client/routes/endUserRoutes';
import type {
  TextTransform,
  BackgroundProps,
  NavbarPosition,
  BackgroundImageProps,
  LinkType,
  LinkWithChildrenProp
} from '@aurora/shared-generated/types/graphql-schema-types';
import type { ColorCssVariableOrValue } from '@aurora/shared-types/styles';
import type { WidgetProps } from '../../common/Widget/types';

export const NavbarLinkTextBorderBottomPrefix = '2px solid ';

export enum NavbarHighlightStyle {
  COLOR = 'color',
  UNDERLINE = 'underline',
  BACKGROUND = 'background',
  CUSTOM = 'custom'
}

export enum NavbarLinkAlignment {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
  SPACE_BETWEEN = 'space-between',
  SPACE_AROUND = 'space-around'
}

export enum LanguagePickerStyle {
  ICON = 'icon',
  LABEL = 'label',
  ICON_LABEL = 'iconAndLabel'
}

/** Link format used by Link Builder forms */
export interface LinkProps {
  /**
   * The id of the link
   */
  id: string;

  /**
   * The type of link
   */
  linkType: LinkType;

  /**
   * Display name of link
   */
  linkLabel: string;

  /**
   * Route of desired page
   */
  goesToPage?: NodePageAndParams;

  /**
   * URL of desired page
   */
  url?: string;

  /**
   * Flag to decide if the link will be opened in a new tab
   */
  openInNewTab?: boolean;

  /** Links that should be rendered underneath this link in a separate menu */
  children?: LinkProps[];
}

export interface NavbarVariantProps extends NavbarFormData {
  mainLinks?: LinkProps[];
  sideLinks?: LinkProps[];
}

export interface NavbarFormData {
  highlightStyle: NavbarHighlightStyle;
  linkTextColor: string;
  linkHighlightColor: string;
  controllerIconColor: string;
  controllerHighlightColor: string;
  linkFontSize: number;
  linkFontStyle: FontStyleName;
  linkAlignment: NavbarLinkAlignment;
  logoHeight: number;
  languagePickerStyle: LanguagePickerStyle;
  useIconLanguagePicker: boolean;
  useLabelLanguagePicker: boolean;
  showLanguagePicker: boolean;
  showSearchIcon: boolean;
  showRegisterLink: boolean;
  showUsername: boolean;
  backgroundColor: ColorCssVariableOrValue;
  backgroundImage: BackgroundImageProps;
  backgroundOpacity: number;
  visualEffects: {
    useStickyPosition: boolean;
    showBottomBorder: boolean;
    showBottomShadow: boolean;
  };
  boundaryPadding: {
    [BoundaryPaddingLocation.TOP]: number;
    [BoundaryPaddingLocation.BOTTOM]: number;
  };
}

export const SKIP_CONTENT = 'main-content';

export interface NavbarStyleProps {
  /** CSS variable: --lia-nav-bg. */
  background?: BackgroundProps;
  /** CSS variable: --lia-nav-bg-opacity. */
  backgroundOpacity?: number;
  /** CSS variable: --lia-nav-border-bottom. */
  borderBottom?: string;
  /** CSS variable: --lia-nav-box-shadow. */
  boxShadow?: string;
  /** CSS variable: --lia-nav-brand-logo-height. */
  brandLogoHeight?: string;
  /** CSS variable: --lia-nav-brand-mr. */
  brandMarginRight?: string;
  /** CSS variable: --lia-nav-brand-mr-sm. */
  brandMarginRightSm?: string;
  /** CSS variable: --lia-nav-collapse-menu-ml. */
  collapseMenuMarginLeft?: string;
  /** CSS variable: --lia-nav-controller-bg-hover-color. */
  controllerBgHoverColor?: string;
  /** CSS variable: --lia-nav-controller-border-radius. */
  controllerBorderRadius?: string;
  /** CSS variable: --lia-nav-controller-icon-highlight. */
  controllerHighlightColor?: string;
  /** CSS variable: --lia-nav-controller-icon-highlight-text. */
  controllerHighlightTextColor?: string;
  /** CSS variable: --lia-nav-controller-icon-color. */
  controllerIconColor?: string;
  /** CSS variable: --lia-nav-controller-icon-hover-color. */
  controllerIconHoverColor?: string;
  /** CSS variable: --lia-nav-controller-text-color. */
  controllerTextColor?: string;
  /** CSS variable: --lia-nav-controller-text-hover-color. */
  controllerTextHoverColor?: string;
  /** CSS variable: --lia-nav-dropdown-border-color. */
  dropdownBorderColor?: string;
  /** CSS variable: --lia-nav-dropdown-divider-mb. */
  dropdownDividerMarginBottom?: string;
  /** CSS variable: --lia-nav-dropdown-divider-mt. */
  dropdownDividerMarginTop?: string;
  /** CSS variable: --lia-nav-dropdown-menu-offset. */
  dropdownMenuOffset?: string;
  /** CSS variable: --lia-nav-dropdown-pb. */
  dropdownPaddingBottom?: string;
  /** CSS variable: --lia-nav-dropdown-pt. */
  dropdownPaddingTop?: string;
  /** CSS variable: --lia-nav-dropdown-px. */
  dropdownPaddingX?: string;
  /** CSS variable: --lia-nav-hamburger-bg-color. */
  hamburgerBgColor?: string;
  /** CSS variable: --lia-nav-hamburger-bg-hover-color. */
  hamburgerBgHoverColor?: string;
  /** CSS variable: --lia-nav-hamburger-border. */
  hamburgerBorder?: string;
  /** CSS variable: --lia-nav-hamburger-border-hover. */
  hamburgerBorderHover?: string;
  /** CSS variable: --lia-nav-hamburger-color. */
  hamburgerColor?: string;
  /** CSS variable: --lia-nav-hamburger-hover-color. */
  hamburgerHoverColor?: string;
  /** CSS variable: --lia-nav-link-bg-color. */
  linkBgColor?: string;
  /** CSS variable: --lia-nav-link-bg-hover-color. */
  linkBgHoverColor?: string;
  /** CSS variable: --lia-nav-link-border. */
  linkBorder?: string;
  /** CSS variable: --lia-nav-link-border-hover. */
  linkBorderHover?: string;
  /** CSS variable: --lia-nav-link-border-radius. */
  linkBorderRadius?: string;
  /** CSS variable: --lia-nav-link-box-shadow. */
  linkBoxShadow?: string;
  /** CSS variable: --lia-nav-link-box-shadow-hover. */
  linkBoxShadowHover?: string;
  /** CSS variable: --lia-nav-link-color. */
  linkColor?: string;
  /** CSS variable: --lia-nav-link-dropdown-px. */
  linkDropdownPaddingX?: string;
  /** CSS variable: --lia-nav-link-dropdown-py. */
  linkDropdownPaddingY?: string;
  /** CSS variable: --lia-nav-link-font-size. */
  linkFontSize?: string;
  /** CSS variable: --lia-nav-link-font-style. */
  linkFontStyle?: string;
  /** CSS variable: --lia-nav-link-font-weight. */
  linkFontWeight?: string;
  /** CSS variable: --lia-nav-link-gap. */
  linkGap?: string;
  /** CSS variable: --lia-nav-link-hover-color. */
  linkHoverColor?: string;
  /** CSS variable: --lia-nav-link-justify-content. */
  linkJustifyContent?: string;
  /** CSS variable: --lia-nav-link-letter-spacing. */
  linkLetterSpacing?: string;
  /** CSS variable: --lia-nav-link-px. */
  linkPaddingX?: string;
  /** CSS variable: --lia-nav-link-py. */
  linkPaddingY?: string;
  /** CSS variable: --lia-nav-link-text-border-bottom. */
  linkTextBorderBottom?: string;
  /** CSS variable: --lia-nav-link-text-border-bottom-hover. */
  linkTextBorderBottomHover?: string;
  /** CSS variable: --lia-nav-link-text-transform. */
  linkTextTransform?: TextTransform;
  /** CSS variable: --lia-nav-pb. */
  paddingBottom?: string;
  /** CSS variable: --lia-nav-pt. */
  paddingTop?: string;
  /** CSS variable: none */
  position?: NavbarPosition;
  /** CSS variable: --lia-nav-collapse-menu-divider-bg. */
  collapseMenuDividerBg?: string;
  /** CSS variable: --lia-nav-collapse-menu-divider-opacity. */
  collapseMenuDividerOpacity?: number;
}

export interface NavbarLinkProps {
  /** The Navbar main links. */
  mainLinks: LinkWithChildrenProp[];
  /** The Navbar side links. */
  sideLinks: LinkWithChildrenProp[];
}

export interface NavbarWidgetProps extends WidgetProps {
  style: NavbarStyleProps;
  links: NavbarLinkProps;
  /** Show register link property. */
  showRegisterLink?: boolean;
  /** Show search icon property. */
  showSearchIcon?: boolean;
  /** Show username property. */
  showUserName?: boolean;
  /** Show language picker property. */
  showLanguagePicker?: boolean;
  /** Language picker icon property. */
  useIconLanguagePicker?: boolean;
  /** Language picker label property. */
  useLabelLanguagePicker?: boolean;
  /** Language picker style property. */
  languagePickerStyle?: LanguagePickerStyle;
}
