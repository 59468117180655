import type { MediaDimensions } from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import { IconSize } from '../../components/common/Icon/enums';

const IconSizeToDimensionsMap: Record<IconSize, MediaDimensions> = {
  [IconSize.FROM_CSS]: null,
  [IconSize.PX_10]: { height: 10, width: 10 },
  [IconSize.PX_12]: { height: 12, width: 12 },
  [IconSize.PX_14]: { height: 14, width: 14 },
  [IconSize.PX_16]: { height: 16, width: 16 },
  [IconSize.PX_20]: { height: 20, width: 20 },
  [IconSize.PX_24]: { height: 24, width: 24 },
  [IconSize.PX_30]: { height: 30, width: 30 },
  [IconSize.PX_40]: { height: 40, width: 40 },
  [IconSize.PX_50]: { height: 50, width: 50 },
  [IconSize.PX_60]: { height: 60, width: 60 },
  [IconSize.PX_80]: { height: 80, width: 80 },
  [IconSize.PX_120]: { height: 120, width: 120 },
  [IconSize.PX_160]: { height: 160, width: 160 }
};

function mediaDimensionsFromIconSize(iconSize: IconSize): MediaDimensions {
  return IconSizeToDimensionsMap[iconSize];
}

// eslint-disable-next-line import/prefer-default-export
export { mediaDimensionsFromIconSize };
