import { canUseDOM } from 'exenv';
import type { Dispatch, SetStateAction } from 'react';
import { useRef } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import useMeasureEnhanced from '@aurora/shared-client/components/useMeasureEnhanced';

/**
 * Callback used when the element has mounted and the dynamic stylesheet has been created.
 *
 * @callback
 *
 * @param sheet The dynamic stylesheet that can be used to add rules to the page.
 * @param entry The `ResizeObserverEntry` for the element associated to the ref.
 * @param element The element associated to the ref.
 */
type UseDynamicCssCallback<E extends HTMLElement = HTMLElement> = (
  sheet: CSSStyleSheet,
  entry: ResizeObserverEntry,
  element: E | null
) => void;

/**
 * A hook that allows for CSS to be dynamically added to the page.
 * The callback function provides access to a `CSSStyleSheet` as well
 * as a `ResizeObserverEntry` for the element associated to the ref that will
 * update each time it's size changes.
 *
 * Allows for a custom document to be passed in the case this hook is used in
 * an iframe.
 *
 * @author Adam Ayres
 */
const useDynamicCss = <E extends HTMLElement = HTMLElement>(
  callback: UseDynamicCssCallback,
  useMeasure = true,
  localDocument: Document = canUseDOM ? document : undefined
): Dispatch<SetStateAction<E>> => {
  const { ref, entry, element } = useMeasureEnhanced(useMeasure);
  const styleElementAndSheet =
    useRef<{ styleElement: HTMLStyleElement; sheet: CSSStyleSheet }>(null);

  useIsomorphicLayoutEffect(() => {
    if (!styleElementAndSheet.current) {
      const styleElement = localDocument.createElement('style');
      localDocument.head.append(styleElement);
      styleElementAndSheet.current = {
        styleElement,
        sheet: styleElement.sheet
      };
    }

    if (styleElementAndSheet.current && ((useMeasure && entry) || !useMeasure)) {
      callback(styleElementAndSheet.current.sheet, entry, element);
    }

    return (): void => {
      if (styleElementAndSheet.current) {
        styleElementAndSheet.current.styleElement.remove();
        styleElementAndSheet.current = null;
      }
    };
  }, [callback, element, entry, localDocument, useMeasure]);

  return ref;
};

export default useDynamicCss;
