import type { ThemeResultFragment } from '@aurora/shared-generated/types/graphql-types';
import React from 'react';

interface MultiThemeContextInterface {
  /**
   * The default theme
   */
  defaultTheme: ThemeResultFragment;
  /**
   * The EndUser theme.
   */
  endUserTheme: ThemeResultFragment;
  /**
   * The Admin theme.
   */
  adminTheme: ThemeResultFragment;
}

/**
 * A React Context that contains the EndUser app and Admin app themes.
 *
 * @author Adam Ayres
 */
const MultiThemeContext = React.createContext<MultiThemeContextInterface>({
  defaultTheme: null,
  endUserTheme: null,
  adminTheme: null
});

export default MultiThemeContext;
