import type { ContentBorder } from '@aurora/shared-generated/types/graphql-schema-types';

export enum ElementsBorderRadius {
  NONE = 'none',
  SMALL = 'small',
  LARGE = 'large'
}

export enum ElementsBorderShadow {
  NONE = 'none',
  LIGHT = 'light',
  DARK = 'dark'
}

export enum ElementsInputShape {
  SQUARE = 'square',
  ROUNDED = 'rounded',
  PILL = 'pill'
}

export enum ElementsModalOverlay {
  LIGHT = 'light',
  DARK = 'dark'
}

interface ThemeEditorElementsResult {
  /**
   * General element border radius.
   */
  borderRadius: ElementsBorderRadius;

  /**
   * Border for main content.
   */
  mainContentBorder: ContentBorder;

  /**
   * Border for side content.
   */
  sideContentBorder: ContentBorder;

  /**
   * General element box shadows.
   */
  shadows: ElementsBorderShadow;

  /**
   * Input element border radius.
   */
  inputShape: ElementsInputShape;

  /**
   * Modal overlay color.
   */
  modalOverlay: ElementsModalOverlay | string;
}

export default ThemeEditorElementsResult;
