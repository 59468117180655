import { ContentBorder } from '@aurora/shared-generated/types/graphql-schema-types';
import {
  ElementsBorderRadius,
  ElementsBorderShadow,
  ElementsInputShape,
  ElementsModalOverlay
} from '@aurora/shared-client/components/community/ThemeEditorResult/ThemeEditorElementResult';

export const enum ElementsConstants {
  DarkBorder = 'hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0.16)',
  LightBorder = 'hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0.08)',
  Black = 'var(--lia-bs-black)',
  White = 'var(--lia-bs-white)',
  NoShadowXs = '0 0 0 1px hsla(var(--lia-bs-gray-900-h), var(--lia-bs-gray-900-s), var(--lia-bs-gray-900-l), 0.08)',
  DarkShadowXs = '0 0 0 1px hsla(var(--lia-bs-gray-900-h), var(--lia-bs-gray-900-s), var(--lia-bs-gray-900-l), 0.08), 0 3px 0 -1px hsla(var(--lia-bs-gray-900-h), var(--lia-bs-gray-900-s), var(--lia-bs-gray-900-l), 0.16)',
  NoShadow = '0',
  DarkShadow = '0 5px 15px hsla(var(--lia-bs-gray-900-h), var(--lia-bs-gray-900-s), var(--lia-bs-gray-900-l), 0.3)',
  DarkShadowSm = '0 2px 4px hsla(var(--lia-bs-gray-900-h), var(--lia-bs-gray-900-s), var(--lia-bs-gray-900-l), 0.12)',
  DarkShadowLg = '0 10px 30px hsla(var(--lia-bs-gray-900-h), var(--lia-bs-gray-900-s), var(--lia-bs-gray-900-l), 0.3)',
  LightShadowXs = '0 0 0 1px hsla(var(--lia-bs-gray-900-h), var(--lia-bs-gray-900-s), var(--lia-bs-gray-900-l), 0.08), 0 3px 0 -1px hsla(var(--lia-bs-gray-900-h), var(--lia-bs-gray-900-s), var(--lia-bs-gray-900-l), 0.08)',
  LightShadow = '0 5px 15px hsla(var(--lia-bs-gray-900-h), var(--lia-bs-gray-900-s), var(--lia-bs-gray-900-l), 0.15)',
  LightShadowSm = '0 2px 4px hsla(var(--lia-bs-gray-900-h), var(--lia-bs-gray-900-s), var(--lia-bs-gray-900-l), 0.06)',
  LightShadowLg = '0 10px 30px hsla(var(--lia-bs-gray-900-h), var(--lia-bs-gray-900-s), var(--lia-bs-gray-900-l), 0.15)',
  PillInput = '100vw',
  RoundInput = 'var(--lia-bs-border-radius)',
  RoundSmInput = 'var(--lia-bs-border-radius-sm)',
  RoundLgInput = 'var(--lia-bs-border-radius-lg)',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  SquareInput = '0'
}

type ElementsBorderRadiusOptions = Record<ElementsBorderRadius, string>;

export const BorderRadiusOptions: ElementsBorderRadiusOptions = {
  large: '10px',
  small: '5px',
  none: '0'
};

export const BorderRadiusLgOptions: ElementsBorderRadiusOptions = {
  large: '18px',
  small: '9px',
  none: '0'
};

export const BorderRadiusSmOptions: ElementsBorderRadiusOptions = {
  large: '6px',
  small: '3px',
  none: '0'
};

type ElementsInputShapeOptions = Record<ElementsInputShape, string>;

export const InputShapeOptions: ElementsInputShapeOptions = {
  pill: ElementsConstants.PillInput,
  rounded: ElementsConstants.RoundInput,
  square: ElementsConstants.SquareInput
};

export const InputShapeSmOptions: ElementsInputShapeOptions = {
  pill: ElementsConstants.PillInput,
  rounded: ElementsConstants.RoundSmInput,
  square: ElementsConstants.SquareInput
};

export const InputShapeLgOptions: ElementsInputShapeOptions = {
  pill: ElementsConstants.PillInput,
  rounded: ElementsConstants.RoundLgInput,
  square: ElementsConstants.SquareInput
};

export const InputShapeTextAreaOptions: ElementsInputShapeOptions = {
  pill: '20px',
  rounded: ElementsConstants.RoundInput,
  square: ElementsConstants.SquareInput
};

export const InputShapeCheckboxOptions: ElementsInputShapeOptions = {
  pill: '3px',
  rounded: '3px',
  square: ElementsConstants.SquareInput
};

type ElementsBorderShadowOptions = Record<ElementsBorderShadow, string>;

export const BorderShadowXsOptions: ElementsBorderShadowOptions = {
  dark: ElementsConstants.DarkShadowXs,
  light: ElementsConstants.LightShadowXs,
  none: ElementsConstants.NoShadowXs
};

export const BorderShadowOptions: ElementsBorderShadowOptions = {
  dark: ElementsConstants.DarkShadow,
  light: ElementsConstants.LightShadow,
  none: ElementsConstants.NoShadow
};

export const BorderShadowSmOptions: ElementsBorderShadowOptions = {
  dark: ElementsConstants.DarkShadowSm,
  light: ElementsConstants.LightShadowSm,
  none: ElementsConstants.NoShadow
};

export const BorderShadowLgOptions: ElementsBorderShadowOptions = {
  dark: ElementsConstants.DarkShadowLg,
  light: ElementsConstants.LightShadowLg,
  none: ElementsConstants.NoShadow
};

/**
 * Since the only difference in the algorithm for each border radius style is the sizes output, we can abstract that and shorten the rest of the fns
 */
function renderBorderRadiusStyleWithOptions(
  radius: ElementsBorderRadius,
  options: ElementsBorderRadiusOptions
) {
  switch (radius) {
    case ElementsBorderRadius.LARGE: {
      return options.large;
    }
    case ElementsBorderRadius.SMALL: {
      return options.small;
    }
    case ElementsBorderRadius.NONE: {
      return options.none;
    }
    /** No selection was made, which implies that a custom value existed in the theme. We return null to allow {@link ThemeEditorPublishHelper} to fall back to the existing value */
    default: {
      return null;
    }
  }
}

/* Returns the border radius style value based on element selection */
export function renderBorderRadiusStyle(radius: ElementsBorderRadius): string {
  return renderBorderRadiusStyleWithOptions(radius, BorderRadiusOptions);
}

/* Returns the border radius large style value based on element selection */
export function renderBorderRadiusLgStyle(radius: ElementsBorderRadius): string {
  return renderBorderRadiusStyleWithOptions(radius, BorderRadiusLgOptions);
}

/* Returns the border radius small style value based on element selection */
export function renderBorderRadiusSmStyle(radius: ElementsBorderRadius): string {
  return renderBorderRadiusStyleWithOptions(radius, BorderRadiusSmOptions);
}

/* Returns the border color style value based on element selection */
export function renderBorderStyle(color: ContentBorder | string): string {
  switch (color) {
    case ContentBorder.Dark: {
      return `1px solid ${ElementsConstants.DarkBorder}`;
    }
    case ContentBorder.Light: {
      return `1px solid ${ElementsConstants.LightBorder}`;
    }
    case ContentBorder.None: {
      return 'none';
    }
    default: {
      return color;
    }
  }
}

/**
 * since the only difference in the algo for each input border radius style is the sizes output, we can abstract that and shorten the other fns
 */
function renderInputShapeStyleWithOptions(
  shape: ElementsInputShape,
  options: ElementsInputShapeOptions
) {
  switch (shape) {
    case ElementsInputShape.PILL: {
      return options.pill;
    }
    case ElementsInputShape.ROUNDED: {
      return options.rounded;
    }
    case ElementsInputShape.SQUARE: {
      return options.square;
    }
    default: {
      /** No selection was made, which implies that a custom value existed in the theme. We return null to allow {@link ThemeEditorPublishHelper} to fall back to the existing value */
      return null;
    }
  }
}

/* Returns the input border radius style value based on element selection */
export function renderInputShapeStyle(shape: ElementsInputShape): string {
  return renderInputShapeStyleWithOptions(shape, InputShapeOptions);
}

/* Returns the input border radius small style value based on element selection */
export function renderInputShapeSmStyle(shape: ElementsInputShape): string {
  return renderInputShapeStyleWithOptions(shape, InputShapeSmOptions);
}

/* Returns the input border radius large style value based on element selection */
export function renderInputShapeLgStyle(shape: ElementsInputShape): string {
  return renderInputShapeStyleWithOptions(shape, InputShapeLgOptions);
}

/* Returns the text area border radius value based on element selection */
export function renderInputShapeTextAreaStyle(shape: ElementsInputShape): string {
  return renderInputShapeStyleWithOptions(shape, InputShapeTextAreaOptions);
}

/* Returns the checkbox border radius value based on element selection */
export function renderCheckboxBorderRadius(shape: ElementsInputShape): string {
  return renderInputShapeStyleWithOptions(shape, InputShapeCheckboxOptions);
}

/* Returns the modal overlay style value based on element selection */
export function renderModalBackdropStyle(style: ElementsModalOverlay | string): string {
  if (style === ElementsModalOverlay.DARK) {
    return ElementsConstants.Black;
  } else if (style === ElementsModalOverlay.LIGHT) {
    return ElementsConstants.White;
  } else {
    /** No selection was made, which implies that a custom value existed in the theme. We can return that custom value to allow it to be maintained. */
    return style;
  }
}

/**
 * since the only difference in the algo for each shadow style is the output, we can abstract that and shorten the other fns
 */
function renderShadowStyleWithOptions(
  shadow: ElementsBorderShadow,
  options: ElementsBorderShadowOptions
) {
  switch (shadow) {
    case ElementsBorderShadow.DARK: {
      return options.dark;
    }
    case ElementsBorderShadow.LIGHT: {
      return options.light;
    }
    case ElementsBorderShadow.NONE: {
      return options.none;
    }
    default: {
      return null;
    }
  }
}

/* Returns the box shadow style value based on element selection */
export function renderShadowXsStyle(shadow: ElementsBorderShadow): string {
  return renderShadowStyleWithOptions(shadow, BorderShadowXsOptions);
}

/* Returns the box shadow style value based on element selection */
export function renderShadowStyle(shadow: ElementsBorderShadow): string {
  return renderShadowStyleWithOptions(shadow, BorderShadowOptions);
}

/* Returns the box shadow small style value based on element selection */
export function renderShadowSmStyle(shadow: ElementsBorderShadow): string {
  return renderShadowStyleWithOptions(shadow, BorderShadowSmOptions);
}

/* Returns the box shadow large style value based on element selection */
export function renderShadowLgStyle(shadow: ElementsBorderShadow): string {
  return renderShadowStyleWithOptions(shadow, BorderShadowLgOptions);
}
